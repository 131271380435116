<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      transition="dialog-top-transition"
    >
      <v-card>
        <!-- Necesaria para el tamano de 500 -->
        <v-card
          flat
          class="mx-auto pt-5"
          max-width="600"
          :loading="validatingStripe"
          :disabled="validatingStripe"
        >
          <v-card-title>
            <h2 class="display-1 font-weight-light pt-5 pl-5">
              Añadir tarjeta de crédito
            </h2>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-col cols="12" v-if="error">
                <v-alert
                  border="left"
                  colored-border
                  type="error"
                  elevation="2"
                >
                  <strong v-if="code">{{ code }}</strong>
                  {{ message }}
                </v-alert>
              </v-col>

              <h3 class="pl-5 pt-5">Datos de la tarjeta</h3>

              <v-text-field
                id="card-holder-name"
                v-model="cardHolderName"
                class="mt-5 mb-7 mx-5 pt-5"
                label="Nombre titular"
                hide-details
              ></v-text-field>

              <div class="ma-5">
                <!-- Stripe Elements Placeholder -->
                <div id="card-element"></div>
              </div>

              <v-checkbox
                :disabled="paymentMethods === 0"
                v-model="defaultMethod"
                class="mt-7 mb-5 mx-5"
                label="Utilizar por defecto"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-container>
          </v-card-text>
          <v-card-actions class="mx-5 pr-6">
            <div class="flex-grow-1"></div>
            <v-btn color="primary" dark @click="$emit('close')"> Cerrar </v-btn>
            <v-btn
              color="primary"
              dark
              @click="save"
              :loading="validatingStripe"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import errors from '@/mixins/errors';
import { STRIPE_KEY } from '@/constants';
import { loadStripe } from '@stripe/stripe-js';

export default {
  mixins: [errors],

  props: {
    dialog: {
      required: true,
      type: Boolean,
      default: false,
    },
    paymentMethods: {
      required: true,
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    stripe: null,
    cardElements: null,
    intent: false,
    cardHolderName: '',
    defaultMethod: false,

    validatingStripe: false,
  }),

  created() {
    // If the customer doesnt have any payment methods
    // we must mark this as default one
    if (this.paymentMethods === 0) {
      this.defaultMethod = true;
    }
  },

  watch: {
    async dialog(newValue) {
      if (!newValue) {
        this.intent = false;
        this.stripe = false;
        return;
      }

      if (!this.intent) {
        this.$store
          .dispatch(
            'paymentMethod/createSetupIntent',
            this.$route.params.restaurant
          )
          .then(response => {
            this.intent = response.intent;
          })
          .catch(error => {
            console.log(error);
          });
      }

      if (!this.stripe) {
        this.stripe = await loadStripe(STRIPE_KEY);

        const elements = this.stripe.elements();
        this.cardElements = elements.create('card');
        this.cardElements.mount('#card-element');
      }
    },
  },

  methods: {
    resetData() {
      this.stripe = null;
      this.cardElements = null;
      this.intent = false;
      this.cardHolderName = '';
      this.defaultMethod = false;

      this.validatingStripe = false;
    },

    async save() {
      // Clear all errors
      this.clearErrors();
      this.validatingStripe = true;

      const { setupIntent, error } = await this.stripe.confirmCardSetup(
        this.intent.client_secret,
        {
          payment_method: {
            card: this.cardElements,
            billing_details: { name: this.cardHolderName },
          },
        }
      );

      if (error) {
        // Go to backend and store the payment method
        this.validatingStripe = false;
        // show error
        this.storeError(error);
      } else {
        // Setup
        this.storePaymentMethod(setupIntent);
      }
    },

    storePaymentMethod(setupIntent) {
      // save the newly created payment method to the server
      const payload = {
        restaurant: this.$route.params.restaurant,
        payment_method: setupIntent.payment_method,
        default_method: this.defaultMethod,
      };

      this.$store
        .dispatch('paymentMethod/store', payload)
        .then(results => {
          this.$emit('cardAdded', results.data);
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.resetData();
        });
    },
  },
};
</script>

<style></style>
