<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('paymentMethods.title') }}
          </h2>
        </v-col>

        <v-col cols="12" md="6" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <v-col cols="12" md="6" v-if="success">
          <v-alert
            border="left"
            colored-border
            :type="messageType"
            elevation="2"
          >
            {{ successMessage }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <v-card elevation="0">
                <v-list
                  subheader
                  :three-line="paymentMethods.length > 0"
                  :two-line="paymentMethods.length == 0"
                >
                  <v-subheader inset class="text-uppercase">
                    Métodos de pago
                  </v-subheader>

                  <v-list-item
                    v-for="paymentMethod in paymentMethods"
                    :key="paymentMethod.id"
                  >
                    <v-list-item-avatar>
                      <v-icon class="lighten-1">
                        mdi-credit-card-settings
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ paymentMethod.billing_details.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ capitalize(paymentMethod.card.brand) }}
                        <small>
                          ****
                          {{ paymentMethod.card.last4 }}
                        </small>
                        <v-tooltip
                          top
                          v-if="defaultPaymentMethod.id === paymentMethod.id"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="mx-2"
                              color="blue-grey"
                              small
                              dark
                              :input-value="true"
                              filter
                              v-bind="attrs"
                              v-on="on"
                            >
                              por defecto
                            </v-chip>
                          </template>
                          <span>
                            Método de pago utilizado en los siguientes pagos.
                          </span>
                        </v-tooltip>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-text="'Caducidad ' + expirationDate(paymentMethod)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-tooltip
                        top
                        v-if="defaultPaymentMethod.id === paymentMethod.id"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </template>
                        <span>Tarjeta activa, no se puede eliminar</span>
                      </v-tooltip>

                      <v-menu offset-y v-else>
                        <template v-slot:activator="{ on: menu, attrs }">
                          <v-btn icon v-bind="attrs" v-on="{ ...menu }">
                            <v-icon> mdi-dots-horizontal </v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="updatePayment(paymentMethod)">
                            <v-list-item-title>
                              Usar predeterminada
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="removePayment(paymentMethod)">
                            <v-list-item-title class="red--text">
                              Eliminar
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="paymentMethods.length == 0">
                    <v-list-item-avatar></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        No tienes ningún metodo de pago.
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Añade uno nuevo con el boton de abajo.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-avatar></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a @click="addPaymentMethod">
                          + Añadir método de pago
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->

    <!-- Añadir metodo de pago - Dialogo -->
    <EasyServeCardDialog
      :dialog="dialog"
      :paymentMethods="paymentMethods.length"
      @close="dialog = false"
      @cardAdded="cardAdded($event)"
    ></EasyServeCardDialog>
    <!-- Añadir metodo de pago - Dialogo -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';

import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';
import EasyServeCardDialog from './components/EasyServeCardDialog';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EasyServeCardDialog,
  },

  data: () => ({
    show: false,
    dialog: false,

    messageType: '',
    success: false,
    successMessage: '',

    timeout: null,

    restaurant: {},
  }),

  computed: {
    ...mapGetters('paymentMethod', {
      paymentMethods: 'paymentMethods',
      defaultPaymentMethod: 'defaultPaymentMethod',
      loading: 'loading',
    }),
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      this.clearErrors();

      return this.$store
        .dispatch(
          'paymentMethod/loadPaymentMethods',
          this.$route.params.restaurant
        )
        .then(response => {
          this.restaurant = response.restaurant;
        })
        .catch(error => {
          this.saveError(error);
        });
    },

    updatePayment(method) {
      this.clearErrors();
      this.success = false;

      const payload = {
        restaurant: this.$route.params.restaurant,
        payment_method: method.id,
      };

      this.$store
        .dispatch('paymentMethod/updateDefault', payload)
        .then(response => {
          this.load().then(() => {
            this.messageType = response.data.type;
            this.successMessage = response.data.message;
            this.success = true;
          });
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    removePayment(method) {
      this.clearErrors();
      this.success = false;

      const payload = {
        restaurant: this.$route.params.restaurant,
        payment_method: method.id,
      };

      this.$store
        .dispatch('paymentMethod/destroy', payload)
        .then(response => {
          this.load().then(() => {
            this.messageType = response.data.type;
            this.successMessage = response.data.message;
            this.success = true;
          });
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.messageType = error.response.data.type;
            this.successMessage = error.response.data.message;
            this.success = true;
          } else {
            this.storeError(error);
          }
        });
    },

    addPaymentMethod() {
      this.clearErrors();
      this.success = false;
      this.dialog = true;
    },

    cardAdded(event) {
      this.dialog = false;
      this.load().then(() => {
        this.successMessage = event.message;
        this.messageType = event.type;
        this.success = true;
      });
    },

    expirationDate(method) {
      const month =
        method.card.exp_month < 10
          ? '0' + method.card.exp_month
          : method.card.exp_month;
      return month + '/' + method.card.exp_year;
    },

    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style></style>
